import { Injectable } from '@angular/core';
import { CaseType } from '../../shared/enums/case-type';

@Injectable({
  providedIn: 'root'
})
export class KeyFormatService {

  constructor() { }

  public convertKeys(o: any, destinationFormat = CaseType.snake.toString()): any {
    let fn: any = {};
    if (destinationFormat === CaseType.snake) {
      fn = this.toSnake;
    } else if (destinationFormat === CaseType.camel) {
      fn = this.toCamel;
    }
    if (this.isObject(o)) {
      const n: any = {};

      Object.keys(o).forEach((k: any) => {
        n[fn(k)] = this.convertKeys(o[k], destinationFormat);
      });

      return n;
    } else if (this.isArray(o)) {
      return o.map((i: any) => this.convertKeys(i, destinationFormat));
    }
    return o;
  }

  // to convert string to camelCase
  public convertToCamel(data: string): string {
    return data.replace(/(?:^\w|[A-Z]|\b\w)/g, (word: string, index: number) =>
      index === 0 ? word.toLowerCase() : word.toUpperCase()
    ).replace(/\s+/g, '');
  }

  // to convert the snake case object key to camel case
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private toCamel = (s: any) =>
    s.replace(/([-_][a-z])/gi, ($1: string) => $1.toUpperCase().replace('_', ''));

  // to convert the camel case object key to snake case
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  private toSnake = (s: string) =>
    s
      .replace(/[^\w\s.]+/g, ' ')
      .split(/ |\B(?=[A-Z])/)
      .map((word: string) => word.toLowerCase())
      .join('_');

  private isArray(a: any): boolean {
    return Array.isArray(a);
  }

  private isObject(o: any): boolean {
    return o === Object(o) && !this.isArray(o) && typeof o !== 'function';
  }
}
