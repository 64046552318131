import { HttpErrorResponse, HttpInterceptorFn } from '@angular/common/http';
import { inject } from '@angular/core';
import { TokenService } from '../services/token.service';
import { catchError, throwError } from 'rxjs';
import { Router } from '@angular/router';

export const authTokenInterceptor: HttpInterceptorFn = (req, next) => {
  const tokenService = inject(TokenService);
  const token = tokenService.retrieveToken();
  const router = inject(Router);
  if (token) {
    req = req.clone({
      headers: req.headers.set('Authorization', `Bearer ${token}`),
    });
  }
  return next(req).pipe(
    catchError((error: HttpErrorResponse) => {
      const obj = { ...error };
      if (error.status === 401) {
        localStorage.removeItem('token');
        localStorage.removeItem('companyId');
        localStorage.removeItem('llm');
        router.navigate(['login']);
      }
      return throwError(() => obj);
    })
  );
};
