import {
  HttpErrorResponse,
  HttpEvent,
  HttpInterceptorFn,
  HttpResponse,
} from '@angular/common/http';
import { inject } from '@angular/core';
import { KeyFormatService } from '../services/key-format.service';
import { catchError, map, throwError } from 'rxjs';

export const keyFormatInterceptor: HttpInterceptorFn = (req, next) => {
  const keyFormatService = inject(KeyFormatService);
  if (!(req.body instanceof FormData)) {
    const snakeCaseObject = keyFormatService.convertKeys(req.body, 'snake');
    req = req.clone({
      body: snakeCaseObject,
    });
  }

  return next(req).pipe(
    map((event: HttpEvent<any>) => {
      if (event instanceof HttpResponse) {
        const camelCaseObject = keyFormatService.convertKeys(
          event.body,
          'camel'
        );
        event = event.clone({ body: camelCaseObject });
      }
      return event;
    }),
    catchError((error: HttpErrorResponse) => {
      const obj = { ...error };
      const camelCaseObject = keyFormatService.convertKeys(obj.error, 'camel');
      obj.error = camelCaseObject;
      return throwError(() => obj);
    })
  );
};
