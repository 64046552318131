import { Routes } from '@angular/router';
import { authGuard } from './core/guards/auth.guard';

export const routes: Routes = [
  {
    path: 'home',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./features/home/home.component').then((mod) => mod.HomeComponent),
  },
  {
    path: 'main',
    canActivate: [authGuard],
    loadComponent: () =>
      import('./features/main/main.component').then((mod) => mod.MainComponent),
  },
  {
    path: 'login',
    loadComponent: () =>
      import('./features/auth/login/login.component').then(
        (mod) => mod.LoginComponent
      ),
  },
  {
    path: 'sign-up',
    loadComponent: () =>
      import('./features/auth/sign-up/sign-up.component').then(
        (mod) => mod.SignUpComponent
      ),
  },
  {
    path: 'company-onboard',
    loadComponent: () =>
      import('./features/auth/company-onboard/company-onboard.component').then(
        (mod) => mod.CompanyOnboardComponent
      ),
  },
  {
    path: '',
    loadComponent: () =>
      import('./features/landing/landing.component').then(
        (mod) => mod.LandingComponent
      ),
  },
  {
    path: '**',
    loadComponent: () =>
      import('./features/landing/landing.component').then(
        (mod) => mod.LandingComponent
      ),
  },
];
